import React from "react";
import Button from "../Button";

import { Clientes, Container, ClientesContainer } from "./style";
import TranslatorFunction from '../I18n/Translator';

const SellMore = () => {

  const freeTrial = TranslatorFunction({ path: 'freeTrial.message' });
  const days = TranslatorFunction({ path: 'days.message' });
  const yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt = TranslatorFunction({ path: 'yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt.message' });

  return (
    <Container>
      <div className="iphone-bg" />
      <ClientesContainer>
        <Clientes>
         {yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt}
        </Clientes>
        <Button>
          <h2>{freeTrial}</h2>
          <p>30 {days}</p>
        </Button>
      </ClientesContainer>
    </Container>
  );
};

export default SellMore;
