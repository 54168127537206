import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

import { ContainerCardapioMobile, ContainerCardapioWeb } from "./styles";

import Web from "./Web";
import Mobile from "./Mobile";

const Home = () => {
  const initFacebookPixel = () => {
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(`${370014708042714}`, advancedMatching, options);
    ReactPixel.pageView();
    ReactPixel.fbq("track", "PageView");
  };

  useEffect(() => {
    initFacebookPixel();
  }, []);

  return (
    <>
      <ContainerCardapioWeb>
        <Web />
      </ContainerCardapioWeb>
      <ContainerCardapioMobile>
        <Mobile />
      </ContainerCardapioMobile>
    </>
  );
};

export default Home;
