import styled from "styled-components";

export const Container = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Icons = styled.div``;

export const Titulo = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #5d5d5d;
  margin-bottom: 18px;
`;

export const Titulos = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #5d5d5d;
`;

export const Submenu = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #959595;
  margin-bottom: 32px;
  margin-top: 18px;
`;

export const SectionOne = styled.div`
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      height: 42px;
      width: 48px;
    }
    h2 {
      margin-top: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #7b7b7b;
    }
  }
`;
export const SectionTwo = styled.div`
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #7b7b7b;
      margin-top: 10px;
    }

    img {
      height: 42px;
      width: 48px;
    }
  }
`;
