/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    language: {
      message: 'it',
    },
    theSmartestSolutionToPresentYourMenu: {
      message: 'La soluzione più smart per presentare il tuo menù',
    },
    digitalMenu: {
      message: 'menù digitale',
    },
    ordersBy: {
      message:'ordini per',
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message:'My Trip Menu è un menu digitale con foto, accessibile tramite QR Code direttamente dallo smartphone del cliente. Semplice, facile e veloce.',
    },
    knowMore: {
      message:'Sapere di più',
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: 'La soluzione perfetta per i tuoi protocolli di sicurezza',
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: "Con My Trip crei un ambiente sicuro e previeni la trasmissione del nuovo coronavirus.",
    },
    noAppNeeded: {
      message: "Nessuna app necessaria",
    },
    directAccessByQRCode: {
      message:'Accesso diretto tramite QR Code',
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: "I tuoi clienti non dovranno scaricare un'altra app. Basta scansionare il QR Code, attraverso la fotocamera dello smartphone, e il gioco è fatto!",
    },
    start: {
      message:'Iniziare',
    },
    sellMore: {
      message: 'Vendi di più',
    },
    and: {
      message: 'e',
    },
    save: {
      message:'Salva',
    },
    withPrintingCosts: {
      message: 'con costi di stampa.',
    },
    freeTrial: {
      message: 'Prova gratuita',
    },
    days: {
      message: 'giorni',
    },
    theMostSolution: {
      message:'La soluzione migliore',
    },
    smartToPresent: {
      message: 'intelligente per presentare',
    },
    yourMenu: {
      message: 'il tuo menu',
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: 'My Trip Menu è un menu digitale con foto',
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message:'accessibile tramite QR Code direttamente dallo smartphone del cliente',
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'My Trip Menu è un menu digitale con foto, accessibile tramite QR Code direttamente dallo smartphone del cliente.',
    },
    simpleEasyAndFast: {
      message: 'Semplice, facile e veloce',
    },
    simple: {
      message: 'Semplice',
    },
    easy: {
      message: 'Semplice',
    },
    fast: {
      message: 'veloce',
    },
    digital: {
      message: 'Digitale',
    },
    receiveYourOrders: {
      message: 'Ricevi i tuoi ordini',
    },
    by: {
      message: 'per',
    },
    automateTheService: {
      message:'Automatizzare il servizio',
    },
    ofYourDelivery: {
      message: 'della tua consegna',
    },
    automateYourDeliveryService: {
      message:'Automatizza il tuo servizio di consegna',
    },

    yourCustomersAccessA: {
      message: 'I tuoi clienti accedono a uno',
    },
    linkWithYourDigitalMenu: {
      message: 'link al tuo menu digitale',
    },
    chooseTheProductsAndYou: {
      message: 'loro scelgono i prodotti e tu',
    },
    receiveTheOrderCirectlyOn: {
      message: "ricevi l'ordine direttamente su",
    },
    speed: {
      message: 'velocità',
    },
    agility: {
      message: 'Agilità',
    },
    orders: {
      message: 'Richiesta',
    },
    profits: {
      message: 'profitti',
    },
    testServiceLink: {
      message: 'Collegamento al servizio di prova',
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: 'Vendi di più e risparmia sui costi di stampa.',
    },
    aDigitalSolution: {
      message:'Una soluzione digitale',
    },
    forYourCompany: {
      message:'per la tua azienda',
    },

    getAccessToARangeOfFeatures: {
      message: "Avere accesso a una gamma di funzionalità",
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: 'che faciliterà la gestione della tua attività.',
    },
    fullMenuWithPhotos: {
      message: 'Menù completo con foto',
    },
    activateAndDeactivateItemsWithEase: {
      message: 'Accendi e spegni gli oggetti facilmente',
    },
    realTimePriceUpdate: {
      message:'Aggiornamento dei prezzi in tempo reale',
    },
    testNewProductsQuickly: {
      message: 'Prova rapidamente nuovi prodotti',
    },
    selectionFiltersByProductType: {
      message: 'Filtri di selezione per tipo di prodotto',
    },
    yourMenuIsReadyInMinutes: {
      message: 'Il tuo menu è pronto in pochi minuti!',
    },

    loginNow: {
      message: 'Accedi ora',
    },
    registrationSuccessful: {
      message: 'Iscrizione completata correttamente!',
    },
    checkYourEmailInboxAndYourSpam: {
      message: 'Controlla la tua casella di posta e lo spam.',
    },
    pleaseEnterAValidSubdomain: {
      message: 'Inserisci un sottodominio valido.',
    },
    checkAllFields: {
      message: 'Controlla tutti i campi.',
    },
    existingEmailTryAgain: {
      message: 'E-mail esistente. Riprova.',
    },
    invalidEmailTryAgain: {
      message: 'E-mail non valido. Riprova.',
    },
    invalidLastNameTryAgain: {
      message: 'Cognome non valido. Riprova',
    },
    invalidNumberTryAgain: {
      message: 'Numero non valido. Riprova',
    },
    subdomainAlreadyExistsTryAgain: {
      message: 'Il sottodominio esiste già. Riprova.',
    },
    invalidSubdomainTryAgain: {
      message: 'Sottodominio non valido. Riprova.',
    },
    invalidCNPJTryAgain: {
      message: 'CNPJ non valido. Riprova.',
    },

    CNPJAlreadyRegisteredTryAgain: {
      message: 'CNPJ già registrato. Riprova.',
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: "Prova gratuita di 30 giorni!",
    },
    freeTtrialFor30days: {
      message: "Prova gratuita di 30 giorni!",
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: 'Registrati e prova subito le funzionalità di My Trip Menu',
    },
    makeYourRegistration: {
      message: 'Effettua la tua registrazione',
    },
    aboutYou: {
      message: 'A proposito di te',
    },
    mandatoryFilling: {
      message: 'obbligatorio',
    },
    name: {
      message: 'Nome*',
    },
    lastName: {
      message: 'Cognome*',
    },
    enterYourLastName: {
      message: 'Inserire il cognome',
    },
    firstName: {
      message: 'Nome di battesimo*',
    },
    enterYourName: {
      message: 'Inserisci il tuo nome.',
    },
    secondName: {
      message: 'Secondo nome*',
    },
    enterYourSecondName: {
      message: 'Inserisci il tuo secondo nome.',
    },
    contactEmail: {
      message:'Contatto email*',
    },
    enterYourEmail: {
      message: 'Inserisci il tuo indirizzo email.',
    },
    mobileWhatsapp: {
      message: 'Cellulare/Whatsapp',
    },
    enterYourContactNumber: {
      message: 'Inserisci il tuo numero di contatto.',
    },
    pleaseEnterYourValidNumber: {
      message: 'Inserisci il tuo numero valido.',
    },
    aboutTheEstablishments: {
      message:'Informazioni sugli stabilimenti',
    },
    nameOfTheEstablishment: {
      message:'Nome dello stabilimento*',
    },
    enterTheNameOfYourEstablishment: {
      message: 'Inserisci il nome della tua struttura.',
    },
    enterYourCNPJ: {
      message: 'Inserisci il tuo CNPJ.',
    },
    enterAValidCNPJ: {
      message: 'Inserisci un CNPJ valido.',
    },
    chooseAURLForYourMenu: {
      message: 'Scegli un URL per il tuo menu*',
    },
    emailForLogosInTheManager: {
      message: 'Email per i loghi in manager*',
    },
    enterAValidEmail: {
      message: 'Insira um e-mail válido',
    },
    chooseASubdomainForYourMenu: {
      message: 'Scegli un sottodominio per il tuo menu*',
    },
    about: {
      message: 'Di',
    },
    solution: {
      message: 'Soluzione',
    },
    functionality: {
      message: 'Funzionalità',
    },
    whoUse: {
      message:'Chi usa?',
    },
    prices: {
      message: 'Prezzi',
    },
    login: {
      message: 'Login',
    },
    register: {
      message: 'Registrati',
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: "I tuoi clienti accedono ad un link con il tuo menù digitale, scelgono i prodotti e tu ricevi l'ordine direttamente su WhatsApp.",
    },
    digitalMenuModule: {
      message:'Modulo Menu Digitale',
    },
    testFor: {
      message: 'Test di',
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message:'dopo questo periodo, paga solo R$99 al mese.',
    },
    enjoy: {
      message: 'Divertiti!',
    },
    month: {
      message:'mese',
    },
    annualPlan: {
      message: 'piano annuale',
    },
    aDigitalSolutionForYourCompany: {
      message: 'Una soluzione digitale per la tua azienda',
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: 'Avere accesso a una serie di funzionalità che faciliteranno la gestione della tua attività.',
    },
    receiveYourOrdersByWhatsApp: {
      message:'Ricevi i tuoi ordini tramite WhatsApp',
    },
    accessTheDashboard: {
      message: 'Vai alla dashboard',
    },
    registerYourProducts: {
      message:'Registra i tuoi prodotti',
    },
    publishToYourCustomers: {
      message: 'Pubblica per i tuoi clienti',
    },
    whoUsesMyTripMenu: {
      message: "Chi usa il menu Il mio viaggio?",
    },
    anIntelligentSolutionForDifferentSegments: {
      message:'Una soluzione intelligente per diversi segmenti.',
    },
    restaurants: {
      message: 'ristoranti',
    },
    coffeeShops: {
      message:'Caffetterie',
    },
    fastFoods: {
      message: 'Fast Food',
    },
    bars: {
      message: 'Barre',
    },
    pubs: {
      message: 'Pub',
    },
    foodTrucks: {
      message:'Food Truck',
    },
    bakeries: {
      message: 'Panetteria',
    },
    pizzerias: {
      message: 'Pizzerie',
    },
    companyName: {
      message:'Ragione sociale*',
    },
    enterYourCompanyName: {
      message: 'Inserisci il nome della tua azienda.',
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: "I tuoi clienti non dovranno scaricare un'altra app. Basta scansionare il QR Code, attraverso la fotocamera dello smartphone, e il gioco è fatto!",
    },
    digitizeYourMenu: {
      message: 'Digitalizza il tuo menu!',
    },
    moreThan30LanguagesAvailable: {
      message: 'Più di 30 lingue disponibili',
    },
    yourMenuReadyForTheWorld: {
      message: 'Il tuo menu pronto per il mondo',
    },
    quote: {
      message: "L'ossigenazione del menu con nuovi prodotti genera sempre elevati costi di stampa per il cambio menu. Con My Trip Menu, abbiamo aggiunto una nuova bevanda e venduto più di 100 unità nei primi giorni.",
    },
    quote2: {
      message: 'Eduardo - Haka Pub',
    },
    multilingual: {
      message: "multilingue"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "Fino a 11 lingue disponibili per il tuo cliente"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "Portoghese • Giapponese • Spagnolo • Inglese • Tedesco • Italiano • Cinese • Coreano • Russo • Arabo • francese"
    },
    oneClickTranslation: {
      message: "Traduzione con un clic"
    },
    upToTenLanguagesAvailable: {
      message: "Fino a 11 lingue disponibili"
    }
  },
};
