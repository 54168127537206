import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .errornome {
    color: #B20000;    
  }
  @font-face {
    font-family: 'Biko Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Biko Regular'), url('Biko_Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Biko Light';
    font-style: normal;
    font-weight: normal;
    src: local('Biko Light'), url('Biko_Light-Restricted.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Biko Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Biko Bold'), url('Biko_Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Biko Black';
    font-style: normal;
    font-weight: normal;
    src: local('Biko Black'), url('Biko_Black.woff') format('woff');
    }
    
  * {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
  }
  body {
    background-color: white;
  }
`;
