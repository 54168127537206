import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  .el01 {
    position: absolute;
    right: -5rem;
    bottom: 0;
    transform: rotate(155deg);
  }
  .el02 {
    position: absolute;
    right: 0;
    top: 0%;
  }

  .el03 {
    position: absolute;
    left: -8rem;
    bottom: -1rem;
  }
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-top: 70px;
  h1 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #959595;
  }
  .tab {
    width: 83px;
    height: 5px;
    background: #ff952e;
    margin-bottom: 18px;
    margin-top: 18px;
  }
  h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #959595;
  }
`;

export const Valores = styled.div`
  margin-top: 30px;
  .old-price {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .new-price {
    position: relative;
    display: flex;
    justify-content: center;
  }
`;

export const Real1 = styled.h6`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #959595;
  margin-top: 5.5px;
`;

export const RealMes = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #959595;
  text-decoration: line-through;
`;

export const PastMes = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #959595;
  text-decoration: line-through;
`;

export const Real2 = styled.h3`
  color: #7b7b7b;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin-top: 12px;
`;

export const RealMes2 = styled.h2`
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 59px !important;
  line-height: 78px !important;
  text-align: center !important;
  color: #0f82a8 !important;
`;

export const Mes = styled.h1`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 42px !important;
  line-height: 53px !important;
  text-align: center !important;
  color: #0f82a8 !important;
  margin-top: 12px !important;
`;

export const Plano = styled.h6`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #959595;
`;

export const Bold = styled.b`
  color: #ff8815;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`;
