import React from "react";

import restaurante from "../../assets/images6/restaurante.svg";
import pubs from "../../assets/images6/pubs.svg";
import fastfood from "../../assets/images6/fastfood.svg";
import cafeterias from "../../assets/images6/cafeterias.svg";
import foodtrucks from "../../assets/images6/foodtrucks.svg";
import bares from "../../assets/images6/bares.svg";
import padarias from "../../assets/images6/padarias.svg";
import pizzaria from "../../assets/images6/pizzaria.svg";

import {
  Container,
  Icons,
  SectionOne,
  SectionTwo,
  Titulo,
  Submenu,
} from "./styles";

function WhoUseMobile({ LitleDivisor }) {
  return (
    <Container>
      <Titulo>
        Quem usa <br /> My Trip Menu?
      </Titulo>
      <LitleDivisor />
      <Submenu>Uma solução inteligente para diferentes segmentos.</Submenu>

      <Icons>
        <SectionOne>
          <div>
            <img src={restaurante} alt="" />
            <h2>Restaurantes</h2>
          </div>
          <div>
            <img src={cafeterias} alt="" />
            <h2>Cafeterias</h2>
          </div>
          <div>
            <img src={fastfood} alt="" />
            <h2>Fast Foods</h2>
          </div>
          <div>
            <img src={bares} alt="" />
            <h2>Bares</h2>
          </div>
        </SectionOne>
        <SectionTwo>
          <div>
            <img src={pubs} alt="" />
            <h2>Pubs</h2>
          </div>
          <div>
            <img src={foodtrucks} alt="" />
            <h2>Food Trucks</h2>
          </div>
          <div>
            <img src={padarias} alt="" />
            <h2>Padarias</h2>
          </div>
          <div>
            <img src={pizzaria} alt="" />
            <h2>Pizzarias</h2>
          </div>
        </SectionTwo>
      </Icons>
    </Container>
  );
}

export default WhoUseMobile;
