import styled from "styled-components";

export const Logo = styled.img`
  width: 100px;
  height: 28.34px;
  @media (min-width: 1000px) {
    width: 187px;
    height: 53px;
  }
`;

export const Menu = styled.img`
  cursor: pointer;
  width: 24px;
  height: 28.34px;
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const MenuBar = styled.nav`
  width: 90%;
  margin: 24px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuBarNav = styled.nav`
  @media (max-width: 1000px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    margin-right: 40px;
    font-weight: 400;
    line-height: 18px;
    font-size: 18px;
    color: #959595;
    cursor: pointer;
  }

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      text-align: center;
      margin: 0;
      color: white;
      margin-bottom: 16px;
    }
    button {
      height: 42px;
      width: 136px;
      background: #ef7522;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      margin-top: 42px;
      color: white;
      font-size: 16px;
    }
    .cadastro {
      color: #ef7522;
      margin: 0;
      margin-top: 12px;
    }
  }
`;
