import React from "react";

import { Container, AboutContainer } from "./styles";
import Element01 from "../../assets/element_bg01.svg";
import Element02 from "../../assets/elemento13_web.png";
import Element03 from "../../assets/elemento14_web.png";
import Iphone from "../../assets/delivery_wpp_web.png";

import Button from "../Button";
import TranslatorFunction from "../I18n/Translator";

function MenuWppWeb() {
  const receiveYourOrders = TranslatorFunction({
    path: "receiveYourOrders.message",
  });
  const by = TranslatorFunction({ path: "by.message" });
  const automateYourDeliveryService = TranslatorFunction({
    path: "automateYourDeliveryService.message",
  });
  const yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp =
    TranslatorFunction({
      path: "yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp.message",
    });
  const speed = TranslatorFunction({ path: "speed.message" });
  const agility = TranslatorFunction({ path: "agility.message" });
  const orders = TranslatorFunction({ path: "orders.message" });
  const profits = TranslatorFunction({ path: "profits.message" });
  const testServiceLink = TranslatorFunction({
    path: "testServiceLink.message",
  });

  return (
    <Container>
      <div className="images">
        <img src={Element01} alt="" className="left" />
        <img src={Element02} alt="" className="right1" />
        <img src={Element03} alt="" className="right2" />
      </div>
      <AboutContainer>
        <div className="texts">
          <h1>
            {receiveYourOrders} <br />
            {by} WhatsApp
          </h1>
          <h2>{automateYourDeliveryService}</h2>

          <h3>
            {
              yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp
            }
          </h3>

          <div className="wpp-text">
            + {speed} + {agility} + {orders} + {profits}
          </div>
          <a
            href={`${process.env.REACT_APP_DEMOS}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>
              <h4>{testServiceLink}</h4>
            </Button>
          </a>
        </div>
        <div className="image">
          <img src={Iphone} alt="" />
        </div>
      </AboutContainer>
    </Container>
  );
}

export default MenuWppWeb;
