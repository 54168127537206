/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    language: {
      message: "ko",
    },
    theSmartestSolutionToPresentYourMenu: {
      message: "메뉴를 제시하는 가장 스마트한 솔루션",
    },
    digitalMenu: {
      message: "디지털 메뉴",
    },
    ordersBy: {
      message: "주문",
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: "My Trip 메뉴는 고객의 스마트폰에서 직접 QR 코드로 액세스할 수 있는 사진이 포함된 디지털 메뉴입니다. 간단하고 쉽고 빠릅니다.",
    },
    knowMore: {
      message: "더 알고",
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: "보안 프로토콜을 위한 완벽한 솔루션",
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: "내 여행을 통해 안전한 환경을 조성하고 신종 코로나바이러스의 전염을 예방하세요.",
    },
    noAppNeeded: {
      message: "앱이 필요하지 않습니다",
    },
    directAccessByQRCode: {
      message: "QR 코드로 직접 액세스",
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: "고객은 아직 다른 앱을 다운로드할 필요가 없습니다. 스마트폰 카메라로 QR코드를 스캔하면 끝!",
    },
    start: {
      message: "시작한다",
    },
    sellMore: {
      message: "더 많이 팔아라",
    },
    and: {
      message: "그리고",
    },
    save: {
      message: "저장",
    },
    withPrintingCosts: {
      message: "인쇄 비용으로.",
    },
    freeTrial: {
      message: "무료 시험판",
    },
    days: {
      message: "날",
    },
    theMostSolution: {
      message: "최고의 솔루션",
    },
    smartToPresent: {
      message: "현명하게 제시",
    },
    yourMenu: {
      message: "당신의 메뉴",
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: "나의 여행 메뉴는 사진이 있는 디지털 메뉴입니다",
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "고객님의 스마트폰에서 바로 QR코드로 접속",
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "나의 여행 메뉴는 고객의 스마트폰에서 바로 QR코드로 접근할 수 있는 사진이 있는 디지털 메뉴입니다.",
    },
    simpleEasyAndFast: {
      message: "간단하고 쉽고 빠르게",
    },
    simple: {
      message: "간단하고 쉽고 빠르게",
    },
    easy: {
      message: "쉬운",
    },
    fast: {
      message: "빠른",
    },
    digital: {
      message: "디지털",
    },
    receiveYourOrders: {
      message: "주문을 받으십시오",
    },
    by: {
      message: "당",
    },
    automateTheService: {
      message: "서비스 자동화",
    },
    ofYourDelivery: {
      message: "당신의 배달",
    },
    automateYourDeliveryService: {
      message: "배송 서비스 자동화",
    },

    yourCustomersAccessA: {
      message: "고객이 액세스합니다.",
    },
    linkWithYourDigitalMenu: {
      message: "디지털 메뉴와 연결",
    },
    chooseTheProductsAndYou: {
      message: "그들은 제품과 당신을 선택합니다",
    },
    receiveTheOrderCirectlyOn: {
      message: "바로 주문 받기",
    },
    speed: {
      message: "속도",
    },
    agility: {
      message: "민첩",
    },
    orders: {
      message: "요구",
    },
    profits: {
      message: "이익",
    },
    testServiceLink: {
      message: "테스트 서비스 링크",
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: "더 많이 판매하고 인쇄 비용을 절약하십시오.",
    },
    aDigitalSolution: {
      message:"디지털 솔루션",
    },
    forYourCompany: {
      message: "당신의 회사를 위해",
    },
    getAccessToARangeOfFeatures: {
      message: "다양한 기능에 액세스",
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message:"그것은 당신의 사업 관리를 용이하게 할 것입니다.",
    },
    fullMenuWithPhotos: {
      message: "사진이 포함된 전체 메뉴",
    },
    activateAndDeactivateItemsWithEase: {
      message: "항목을 쉽게 활성화 및 비활성화",
    },
    realTimePriceUpdate: {
      message: "실시간 가격 업데이트",
    },
    testNewProductsQuickly: {
      message: "새 제품을 빨리 테스트하십시오",
    },
    selectionFiltersByProductType: {
      message: "제품 유형별 선택 필터",
    },
    yourMenuIsReadyInMinutes: {
      message: "당신의 메뉴는 몇 분 안에 준비됩니다!",
    },
    loginNow: {
      message: "지금 액세스",
    },
    registrationSuccessful: {
      message: "등록 성공!",
    },
    checkYourEmailInboxAndYourSpam: {
      message: "받은 편지함과 스팸함을 확인하세요.",
     },
    pleaseEnterAValidSubdomain: {
      message: "유효한 하위 도메인을 입력하십시오.",
    },
    checkAllFields: {
      message: "모든 필드를 확인하십시오.",
    },
    existingEmailTryAgain: {
      message: "기존 이메일입니다. 다시 시도하십시오.",
    },
    invalidEmailTryAgain: {
      message: "잘못된 이메일입니다. 다시 시도하십시오.",
    },
    invalidLastNameTryAgain: {
      message: "성이 잘못되었습니다. 다시 시도하십시오",
    },
    invalidNumberTryAgain: {
      message: "잘못된 번호. 다시 시도하십시오",
    },
    subdomainAlreadyExistsTryAgain: {
      message: "하위 도메인이 이미 존재합니다. 다시 시도하십시오.",
    },
    invalidSubdomainTryAgain: {
      message: "잘못된 하위 도메인입니다. 다시 시도하십시오.",
    },
    invalidCNPJTryAgain: {
      message: "잘못된 CNPJ입니다. 다시 시도하십시오.",
    },
    CNPJAlreadyRegisteredTryAgain: {
      message: "CNPJ는 이미 등록되었습니다. 다시 시도하십시오.",
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: "예상치 못한 일이 발생했습니다. 나중에 다시 시도하십시오.",
    },
    freeTtrialFor30days: {
      message: "30일 무료 체험!",
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: "지금 바로 가입하고 마이트립 메뉴의 시설을 경험하세요",
    },
    makeYourRegistration: {
      message: "등록하다",
    },
    aboutYou: {
      message: "당신에 대해",
    },
    mandatoryFilling: {
      message: "필수적인",
    },
    name: {
      message: "이름*",
    },
    lastName: {
      message: "성*",
    },
    enterYourLastName: {
      message: "당신의 성을 입력하십시오.",
    },
    firstName: {
      message: "이름*",
    },
    enterYourName: {
      message: "당신의 이름을 입력.",
    },
    secondName: {
      message:"두 번째 이름*",
    },
    enterYourSecondName: {
      message: "중간 이름을 입력하십시오.",
    },
    contactEmail: {
      message: "이메일 연락처*",
    },
    enterYourEmail: {
      message: "이메일을 입력하세요.",
    },
    mobileWhatsapp: {
      message: "모바일/왓츠앱",
    },
    enterYourContactNumber: {
      message: "연락처를 입력하세요.",
    },
    pleaseEnterYourValidNumber: {
      message: "유효한 번호를 입력하십시오.",
    },
    aboutTheEstablishments: {
      message: "시설에 대해",
    },
    nameOfTheEstablishment: {
      message: "시설명*",
    },
    enterTheNameOfYourEstablishment: {
      message: "시설 이름을 입력하십시오.",
    },
    enterYourCNPJ: {
      message: "CNPJ를 입력하십시오.",
    },
    enterAValidCNPJ: {
      message: "유효한 CNPJ를 입력하십시오.",
    },
    chooseAURLForYourMenu: {
      message: "메뉴의 URL을 선택하세요*",
    },
    emailForLogosInTheManager: {
      message: "관리자의 로고용 이메일*",
    },
    enterAValidEmail: {
      message: "유효한 이메일을 입력하십시오",
    },
    chooseASubdomainForYourMenu: {
      message: "메뉴의 하위 도메인을 선택하세요*",
    },
    about: {
      message: "에 대한",
    },
    solution: {
      message: "해결책",
    },
    functionality: {
      message: "기능",
    },
    whoUse: {
      message: "누가 사용합니까?",
    },
    prices: {
      message: "물가",
    },
    login: {
      message: "로그인",
    },
    register: {
      message: "등록하다",
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: "고객은 디지털 메뉴가 있는 링크에 액세스하고 제품을 선택하면 WhatsApp에서 직접 주문을 받습니다.",
    },
    digitalMenuModule: {
      message: "디지털 메뉴 모듈",
    },
    testFor: {
      message: "테스트",
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: "이 기간이 지나면 한 달에 R$ 99만 지불하면 됩니다.",
    },
    enjoy: {
      message: "즐기다!",
    },
    month: {
      message: "월",
    },
    annualPlan: {
      message: "연간 계획",
    },
    aDigitalSolutionForYourCompany: {
      message: "귀사의 디지털 솔루션",
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message:"비즈니스 관리를 용이하게 하는 일련의 기능에 액세스할 수 있습니다.",
    },
    receiveYourOrdersByWhatsApp: {
      message: "WhatsApp으로 주문 받기",
    },
    accessTheDashboard: {
      message: "대시보드에 액세스",
    },
    registerYourProducts: {
      message: "제품 등록",
    },
    publishToYourCustomers: {
      message: "고객에게 게시",
    },
    whoUsesMyTripMenu: {
      message: "누가 내 여행 메뉴를 사용합니까?",
    },
    anIntelligentSolutionForDifferentSegments: {
      message: "다양한 부문을 위한 스마트 솔루션.",
    },
    restaurants: {
      message: "레스토랑",
    },
    coffeeShops: {
      message: "커피 숍",
    },
    fastFoods: {
      message: "패스트 푸드",
    },
    bars: {
      message: "바",
    },
    pubs: {
      message: "펍",
    },
    foodTrucks: {
      message: "Food Trucks",
    },
    bakeries: {
      message: "베이커리",
    },
    pizzerias: {
      message: "피자집",
    },
    companyName: {
      message: "사회적 이유*",
    },
    enterYourCompanyName: {
      message: "회사 이름을 입력하십시오.",
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: "고객은 다른 애플리케이션을 다운로드할 필요가 없습니다. 스마트폰 카메라를 통해 QR 코드를 스캔하기만 하면 됩니다!",
    },
    digitizeYourMenu: {
      message: "메뉴를 스캔하십시오!",
    },
    moreThan30LanguagesAvailable: {
      message: "30개 이상의 언어 사용 가능",
    },
    yourMenuReadyForTheWorld: {
      message: "당신의 메뉴는 세계를 위해 준비되었습니다",
    },
    quote: {
      message: '새로운 제품으로 메뉴에 산소를 공급하면 메뉴를 변경하는 데 항상 높은 인쇄 비용이 발생합니다. 내 여행 메뉴를 사용하여 새로운 음료를 추가하고 처음 며칠 동안 100개 이상을 판매했습니다.',
    },
    quote2: {
      message: '에두아르도 - 하카 펍',
    },
    multilingual: {
      message: "다국어"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "고객이 사용할 수 있는 최대 11개 언어"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "포르투갈어 • 일본어 • 스페인어 • 영어 • 독일어 • 이탈리아어 • 중국어 • 한국어 • 러시아어 • 아랍어 • 프랜시스"
    },
    oneClickTranslation: {
      message: "원 클릭 번역"
    },
    upToTenLanguagesAvailable: {
      message: "최대 11개 언어 사용 가능"
    }
  },
};
