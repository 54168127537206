import styled from "styled-components";

export const ContainerWeb = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
  position: relative;
  display: flex;
  margin: 110px auto;
  justify-content: space-between;
  align-items: center;

  .img {
    width: 30%;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: left;
    align-items: left;
  }

  .texts {
    width: 70%;
    position: relative;
    z-index: 1;
    flex-direction: column;
    display: flex;
    justify-content: left;
    align-items: left;
  }
  .el01 {
    position: absolute;
    top: 10rem;
    right: 0;
  }
`;
export const ContainerMobile = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const Texts = styled.div`
  position: relative;
  z-index: 1;
  width: 70%;
  margin: 0 auto;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 48px;
    color: #f37e0c;
    margin-bottom: 8px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    color: #606060;
    width: 60%;
  }
  .tab {
    width: 83px;
    height: 5px;
    background: #ff952e;
    margin: 16px 0;
  }
`;
export const ContainerForm = styled.div`
  position: relative;
  z-index: 1;
  width: 70%;
  margin: 0 auto;

  .container {
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    .required {
      display: flex;
    }
  }

  @media (min-width: 1000px) {
    .container {
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  .required {
    display: flex;
  }
`;

export const Sobre = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #7b7b7b;
`;

export const Asterisco = styled.h6`
  color: red;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #ff0000;
  position: relative;
  top: -3px;
`;

export const Preenchimento = styled.h6`
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  text-align: right;
  color: #7b7b7b;
`;

export const FormContainer = styled.form`
  width: 90%;
  margin: 0 auto;

  input {
    width: 98%;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 12px;

    ::-webkit-input-placeholder {
      font-style: italic;
    }

    :-moz-placeholder {
      font-style: italic;
    }
    ::-moz-placeholder {
      font-style: italic;
    }
    :-ms-input-placeholder {
      font-style: italic;
    }
  }
  .top {
    margin-top: 11px;
  }
  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
    input {
      width: 96%;
    }
  }
  .form-one {
    margin-bottom: 11px;
  }
  .button-submit {
    margin-top: 28px;

    margin-bottom: 42px;

    button {
      font-weight: 700;
      font-size: 18px;
      height: 46px;
      width: 146px;
      border: none;
      color: white;
      cursor: pointer;
      border-radius: 5px;
      background: #ef7522;
    }
    h5 {
      margin-top: 12px;
    }
  }
  @media (max-width: 1000px) {
    .button-submit {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
`;

export const SobreEstabelecimento = styled.h5`
  margin-top: 26px;
  margin-bottom: 17px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #7b7b7b;
`;
