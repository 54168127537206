/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {

    language: {
      message: 'en',
    },
    theSmartestSolutionToPresentYourMenu: {
      message: 'The smartest solution to present your menu',
    },
    digitalMenu: {
      message: 'Digital Menu',
    },
    ordersBy: {
      message: 'Orders by',
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: 'My Trip Menu is a digital menu with photos, accessed by QR Code directly from the customer\'s smartphone. Simple, easy and fast.',
    },
    knowMore: {
      message: 'know more',
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: 'The perfect solution for your security protocols',
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: 'With My Trip you create a safe environment and prevent the transmission of the new coronavirus.',
    },
    noAppNeeded: {
      message: 'No app needed',
    },
    directAccessByQRCode: {
      message: 'Direct access by QR Code',
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: 'Your customers won\'t need to download yet another app. Just scan the QR Code, through the smartphone camera, and that\'s it!',
    },
    start: {
      message: 'Start',
    },
    sellMore: {
      message: 'Sell more',
    },
    and: {
      message: 'and',
    },
    save: {
      message: 'save',
    },
    withPrintingCosts: {
      message: 'on printing costs',
    },
    freeTrial: {
      message: 'Free trial',
    },
    days: {
      message: 'days',
    },
    theMostSolution: {
      message: 'The smartest',
    },
    smartToPresent: {
      message: 'way to present',
    },
    yourMenu: {
      message: 'your menu',
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: 'My Trip Menu is a digital menu with photos',
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'accessed by QR Code directly from the customer\'s smartphone',
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'My Trip Menu is a digital menu with photos, accessed by QR Code directly from the customer\'s smartphone.',
    },
    simpleEasyAndFast: {
      message: 'Simple, easy and fast',
    },
    simple: {
      message: 'Simple',
    },
    easy: {
      message: 'easy',
    },
    fast: {
      message: 'fast',
    },
    digital: {
      message: 'Digital',
    },
    receiveYourOrders: {
      message: 'Receive your orders',
    },
    by: {
      message: 'by',
    },
    automateTheService: {
      message: 'Automate your ',
    },
    ofYourDelivery: {
      message: 'delivery service',
    },
    automateYourDeliveryService: {
      message: 'Automate your delivery service',
    },

    yourCustomersAccessA: {
      message: 'Your customers access a',
    },
    linkWithYourDigitalMenu: {
      message: 'link with your digital menu,',
    },
    chooseTheProductsAndYou: {
      message: 'choose the products and you',
    },
    receiveTheOrderCirectlyOn: {
      message: 'receive the order directly on',
    },
    speed: {
      message: 'Speed',
    },
    agility: {
      message: 'Agility',
    },
    orders: {
      message: 'Orders',
    },
    profits: {
      message: 'Profits',
    },
    testServiceLink: {
      message: 'Test service link',
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: 'Sell ​​more and save on printing costs.',
    },
    aDigitalSolution: {
      message: 'A digital solution',
    },
    forYourCompany: {
      message: 'for your company',
    },

    getAccessToARangeOfFeatures: {
      message: 'Get access to a series of features',
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: 'that will make managing your business easier.',
    },
    fullMenuWithPhotos: {
      message: 'Full menu with photos',
    },
    activateAndDeactivateItemsWithEase: {
      message: 'Activate and deactivate items with ease',
    },
    realTimePriceUpdate: {
      message: 'Real-time price update',
    },
    testNewProductsQuickly: {
      message: 'Test new products quickly',
    },
    selectionFiltersByProductType: {
      message: 'Selection filters by product type',
    },
    yourMenuIsReadyInMinutes: {
      message: 'Your menu is ready in minutes!',
    },
    loginNow: {
      message: 'Login now',
    },
    registrationSuccessful: {
      message: 'Registration successful!',
    },
    checkYourEmailInboxAndYourSpam: {
      message: 'Check your email inbox and your spam.',
    },
    pleaseEnterAValidSubdomain: {
      message: 'Please enter a valid subdomain.',
    },
    checkAllFields: {
      message: 'Check all fields.',
    },
    existingEmailTryAgain: {
      message: 'Existing email. Try again.',
    },
    invalidEmailTryAgain: {
      message: 'Invalid email. Try again.',
    },
    invalidLastNameTryAgain: {
      message: 'Invalid last name. Try again.',
    },
    invalidNumberTryAgain: {
      message: 'Invalid number. Try again.',
    },
    subdomainAlreadyExistsTryAgain: {
      message: 'Subdomain already exists. Try again.',
    },
    invalidSubdomainTryAgain: {
      message: 'Invalid subdomain. Try again.',
    },
    invalidCNPJTryAgain: {
      message: 'Invalid CNPJ. Try again.',
    },
    CNPJAlreadyRegisteredTryAgain: {
      message: 'CNPJ already registered. Try again.',
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: 'Something unexpected happened. Try again later.',
    },
    freeTtrialFor30days: {
      message: 'Free trial for 30 days!',
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: 'Make your registration and experience the facilities of My Trip Menu right now',
    },
    makeYourRegistration: {
      message: 'Make your registration',
    },
    aboutYou: {
      message: 'About you',
    },
    mandatoryFilling: {
      message: 'mandatory filling',
    },
    name: {
      message: 'Name*',
    },
    lastName: {
      message: 'Last name*',
    },
    enterYourLastName: {
      message: 'Enter your last name.',
    },
    firstName: {
      message: 'First name*',
    },
    enterYourName: {
      message: 'Eter your name.',
    },
    secondName: {
      message: 'Second name*',
    },
    enterYourSecondName: {
      message: 'Enter your middle name.',
    },
    contactEmail: {
      message: 'Contact email*',
    },
    enterYourEmail: {
      message: 'Enter your email.',
    },
    mobileWhatsapp: {
      message: 'Mobile/Whatsapp',
    },
    enterYourContactNumber: {
      message: 'Enter your contact number.',
    },
    pleaseEnterYourValidNumber: {
      message: 'Enter your valid number.',
    },
    aboutTheEstablishments: {
      message: 'About the establishments',
    },
    nameOfTheEstablishment: {
      message: 'Name of the establishment*',
    },
    enterTheNameOfYourEstablishment: {
      message: 'Enter the name of your establishment.',
    },
    enterYourCNPJ: {
      message: 'Enter your CNPJ.',
    },
    enterAValidCNPJ: {
      message: 'Enter a valid CNPJ.',
    },
    chooseAURLForYourMenu: {
      message: 'Choose a URL for your menu*',
    },
    emailForLogosInTheManager: {
      message: 'Email for logos in manager*',
    },
    enterAValidEmail: {
      message: 'Enter a valid email',
    },
    chooseASubdomainForYourMenu: {
      message: 'Choose a subdomain for your menu*',
    },
    about: {
      message: 'About',
    },
    solution: {
      message: 'Solution',
    },
    functionality: {
      message: 'Functionality',
    },
    whoUse: {
      message: 'Who use?',
    },
    prices: {
      message: 'Prices',
    },
    login: {
      message: 'Login',
    },
    register: {
      message: 'Register',
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: 'Your customers access a link with your digital menu, choose the products and you receive the order directly on WhatsApp.',
    },
    digitalMenuModule: {
      message: 'Digital Menu Module',
    },
    testFor: {
      message: 'Test for',
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: 'after this period, pay only R$ 99 per month.',
    },
    enjoy: {
      message: 'Enjoy!',
    },
    month: {
      message: 'month',
    },
    annualPlan: {
      message: 'annual plan',
    },
    aDigitalSolutionForYourCompany: {
      message: 'A digital solution for your company',
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: 'Get access to a series of features that will make managing your business easier.',
    },
    receiveYourOrdersByWhatsApp: {
      message: 'Receive your orders by WhatsApp',
    },
    accessTheDashboard: {
      message: 'access the dashboard',
    },
    registerYourProducts: {
      message: 'Register your products',
    },
    publishToYourCustomers: {
      message: 'Publish to your customers',
    },
    whoUsesMyTripMenu: {
      message: 'Who uses My Trip Menu?',
    },
    anIntelligentSolutionForDifferentSegments: {
      message: 'An intelligent solution for different segments.',
    },
    restaurants: {
      message: 'Restaurants',
    },
    coffeeShops: {
      message: 'Coffee shops',
    },
    fastFoods: {
      message: 'Fast Foods',
    },
    bars: {
      message: 'Bars',
    },
    pubs: {
      message: 'Pubs',
    },
    foodTrucks: {
      message: 'Food Trucks',
    },
    bakeries: {
      message: 'Bakeries',
    },
    pizzerias: {
      message: 'Pizzerias',
    },
    companyName: {
      message: 'Company name*',
    },
    enterYourCompanyName: {
      message: 'Enter your company name.',
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: 'Your customers won\'t need to download yet another app. Just scan the QR Code, through the smartphone camera, and that\'s it!',
    },
    digitizeYourMenu: {
      message: 'Digitize your menu!',
    },
    moreThan30LanguagesAvailable: {
      message: 'More than 30 languages ​​available',
    },
    yourMenuReadyForTheWorld: {
      message: 'Your menu ready for the world',
    },
    quote: {
      message: 'Oxygenating the menu with new products always generates high printing costs for changing menus. With My Trip Menu, we added a new drink and sold more than 100 units in the first few days.',
    },
    quote2: {
      message: 'Eduardo - Haka Pub',
    },
    multilingual: {
      message: "multilingual"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "Up to 11 languages ​​available for your customer"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "Portuguese • Japanese • Spanish • English • German • Italian • Chinese • Korean • Russian • Arabic • French"
    },
    oneClickTranslation: {
      message: "One-click translation"
    },
    upToTenLanguagesAvailable: {
      message: "Up to 11 languages ​​available"
    }    
  }
};
