import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 64px;

  position: relative;

  margin-bottom: 50px;
`;

export const Simple = styled.div`
  position: relative;
  width: 95%;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  .texts {
    .titles {
      display: flex;
      h2 {
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0em;
        color: #959595;
      }
      .digital {
        color: #ef7522;
        margin-left: 12px;
      }
    }
    h3 {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      color: #959595;
      text-align: left;
    }
  }

  .buttons {
    margin-top: 46px;
    div {
      margin-bottom: 12px;
      display: flex;
      button {
        color: white;
        cursor: pointer;
        height: 32px;
        width: 32px;
        border: none;
        border-radius: 4px;
        margin-right: 12px;
        background: #0197ba;
        box-shadow: 3px 1px 0px -1px #ff952e;
      }
    }
  }
  .button {
    margin-top: 40px;
    button {
      cursor: pointer;
      width: 180px;
      height: 42px;
      border: none;
      color: white;
      background: #ef7522;
      border: 1px solid #ef7522;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 600;
      font-size: 17px;
      line-height: 21px;
    }
  }
`;

export const Who = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .titles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    h1 {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: center;
      color: #7b7b7b;
      margin-bottom: 16px;
    }
    .tab {
      width: 83px;
      height: 5px;
      background: #ff952e;
      margin-bottom: 18px;
    }
    h2 {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      color: #959595;
    }
  }

  .icons {
    width: 80%;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h2 {
        margin-top: 10px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        color: #7b7b7b;
        text-align: center;
      }
    }
  }
`;

export const ContainerGrey = styled.div`
  padding-top: 100px;
  background-color: #efefef;
  padding-bottom: 100px;

  .element06 {
    position: absolute;
    right: -5.5rem;
    transform: rotate(160deg);
  }
`;

export const ContainerWhite = styled.div`
  padding-top: 100px;
  position: relative;
  background-color: #fff;
  padding-bottom: 100px;

  .el01 {
    position: absolute;
    left: -6rem;
    bottom: 35%;
    transform: rotate(-30deg);
  }
  .el02 {
    position: absolute;
    right: -2rem;
    bottom: 20%;
  }
`;
