import styled from "styled-components";

export const Hakapub = styled.div`
  background: #ececec;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15rem;

  @media (max-width: 1000px) {
    padding: 0 5rem;
  }
`;

export const Subtexto = styled.h5`
  width: 100% !important;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 132.84%;
  text-align: center;
  color: #7b7b7b;
  width: 90%;
  @media (min-width: 1000px) {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
`;

export const Autor = styled.h6`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-self: end;
  color: #7b7b7b;
  margin-top: 19px;
  @media (min-width: 1000px) {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
