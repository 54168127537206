/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    language: {
      message: "ja",
    },
    theSmartestSolutionToPresentYourMenu: {  
      message: "メニューを表示するための最もスマートなソリューション",
    },
    digitalMenu: {
      message: "デジタルメニュー",
    },
    ordersBy: {
      message: "オーダー",
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: "My Trip 메뉴는 고객의 스마트폰에서 직접 QR 코드로 액세스할 수 있는 사진이 포함된 디지털 메뉴입니다. 간단하고 쉽고 빠릅니다.",
    },
    knowMore: {
      message: "もっと知る",
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: "セキュリティ プロトコルに最適なソリューション",
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: "My Trip を使用すると、安全な環境を作成し、新しいコロナウイルスの感染を防ぐことができます",
    },
    noAppNeeded: {
      message: "アプリは必要ありません",
    },
    directAccessByQRCode: {
      message: "QRコードで直接アクセス",
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: "お客様は別のアプリケーションをダウンロードする必要はありません。スマートフォンのカメラで QR コードをスキャンするだけです!",
    },
    start: {
      message: "始めること",
    },
    sellMore: {
      message: "もっと売る",
    },
    and: {
      message: "と",
    },
    save: {
      message: "保存",
    },
    withPrintingCosts: {
      message: "印刷費込み。」",
    },
    freeTrial: {
      message: "無料お試し",
    },
    days: {
      message: "日々",
    },
    theMostSolution: {
      message: "一番の「解決策」",
    },
    smartToPresent: {
      message: "スマートに提示する",
    },
    yourMenu: {
      message: "あなたのメニュー",
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: "My Trip Menuは写真付きのデジタルメニューです",
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "お客様のスマートフォンから直接QRコードでアクセス",
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "My Trip Menu は、お客様のスマートフォンから直接 QR コードでアクセスできる、写真付きのデジタル メニューです。",
    },
    simpleEasyAndFast: {
      message: "シンプル、簡単、高速",
    },
    simple: {
      message: "単純",
    },
    easy: {
      message: "簡単",
    },
    fast: {
      message: "速い",
    },
    digital: {
      message: "デジタル",
    },
    receiveYourOrders: {
      message: "ご注文を承ります",
    },
    by: {
      message: "あたり",
    },
    automateTheService: {
      message: "サービスを自動化する",
    },
    ofYourDelivery: {
      message: "あなたの配達",
    },
    automateYourDeliveryService: {
      message: "配送サービスを自動化する",
    },

    yourCustomersAccessA: {
      message: "「あなたの顧客は",
    },
    linkWithYourDigitalMenu: {
      message: "デジタルメニューとのリンク,",
    },
    chooseTheProductsAndYou: {
      message: "製品とあなたを選択してください",
    },
    receiveTheOrderCirectlyOn: {
      message: "で直接注文を受ける",
    },
    speed: {
      message: "速度",
    },
    agility: {
      message: "機敏",
    },
    orders: {
      message: "リクエスト",
    },
    profits: {
      message: "利益",
    },
    testServiceLink: {
      message: "テストサービスリンク",
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: "より多くの販売を行い、印刷コストを節約します。",
    },
    aDigitalSolution: {
      message: "デジタルソリューション",
    },
    forYourCompany: {
      message: "あなたの会社のために",
    },

    getAccessToARangeOfFeatures: {
      message: "さまざまな機能にアクセスする",
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: "それはあなたのビジネスの管理を容易にします。",
    },
    fullMenuWithPhotos: {
      message: "写真付きのフルメニュー",
    },
    activateAndDeactivateItemsWithEase: {
      message: "写真付きのフルメニュー",
    },
    realTimePriceUpdate: {
      message: "リアルタイムの価格更新",
    },
    testNewProductsQuickly: {
      message: "新製品をすばやくテストする",
    },
    selectionFiltersByProductType: {
      message: "製品タイプによる選択フィルター",
    },
    yourMenuIsReadyInMinutes: {
      message: "あなたのメニューは数分で準備ができています!",
    },
    loginNow: {
      message: "ここでログイン",
    },
    registrationSuccessful: {
      message: "登録に成功！",
    },
    checkYourEmailInboxAndYourSpam: {
      message: "メールの受信トレイとスパムを確認してください。",
    },
    pleaseEnterAValidSubdomain: {
      message: "有効なサブドメインを入力してください。",
    },
    checkAllFields: {
      message: "すべてのフィールドをチェックします。",
    },
    existingEmailTryAgain: {
      message: "既存の電子メール。 再試行.",
    },
    invalidEmailTryAgain: {
      message: "メールが無効です。 再試行。",
    },
    invalidLastNameTryAgain: {
      message: "姓が無効です。 再試行",
    },
    invalidNumberTryAgain: {
      message: "無効な番号。 再試行",
    },
    subdomainAlreadyExistsTryAgain: {
      message: "サブドメインは既に存在します。 再試行。",
    },
    invalidSubdomainTryAgain: {
      message: "サブドメインが無効です。 再試行。",
    },
    invalidCNPJTryAgain: {
      message: "CNPJ が無効です。 再試行。",
    },

    CNPJAlreadyRegisteredTryAgain: {
      message: "CNPJ は登録済みです。 再試行。",
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: "予期しないことが起こりました。 あとでもう一度試してみてください。",
    },
    freeTtrialFor30days: {
      message: "30日間無料お試し！",
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: "登録して、今すぐ My Trip Menu の機能を体験してください",
    },
    makeYourRegistration: {
      message: "登録する",
    },
    aboutYou: {
      message: "あなたについて",
    },
    mandatoryFilling: {
      message: "必須の記入",
    },
    name: {
      message: "名前*",
    },
    lastName: {
      message: "苗字*",
    },
    enterYourLastName: {
      message: "姓を入力してください.",
    },
    firstName: {
      message: "ファーストネーム*",
    },
    enterYourName: {
      message: "あなたの名前を入力してください。",
    },
    secondName: {
      message: "ミドルネーム*",
    },
    enterYourSecondName: {
      message: "2 番目の名前を入力します。",
    },
    contactEmail: {
      message: "連絡先メールアドレス*",
    },
    enterYourEmail: {
      message: "メールアドレスを入力。",
    },
    mobileWhatsapp: {
      message: "モバイル/Whatsapp",
    },
    enterYourContactNumber: {
      message: "連絡先番号を入力してください。",
    },
    pleaseEnterYourValidNumber: {
      message: "有効な番号を入力してください。",
    },
    aboutTheEstablishments: {
      message: "事業所について",
    },
    nameOfTheEstablishment: {
      message: "施設名*",
    },
    enterTheNameOfYourEstablishment: {
      message: "施設の名前を入力します。",
    },
    enterYourCNPJ: {
      message: "あなたのCNPJを入力してください。",
    },
    enterAValidCNPJ: {
      message: "有効な CNPJ を入力してください。",
    },
    chooseAURLForYourMenu: {
      message: "メニューの URL を選択してください*",
    },
    emailForLogosInTheManager: {
      message: "マネージャーのロゴの電子メール*",
    },
    enterAValidEmail: {
      message: "有効なメールアドレスを入力してください",
    },
    chooseASubdomainForYourMenu: {
      message: "メニューのサブドメインを選択してください*",
    },
    about: {
      message: "約",
    },
    solution: {
      message: "解決",
    },
    functionality: {
      message: "機能性",
    },
    whoUse: {
      message: "誰が使う？",
    },
    prices: {
      message: "価格",
    },
    login: {
      message: "ログイン",
    },
    register: {
      message: "登録",
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: "顧客がデジタル メニューのリンクにアクセスし、製品を選択すると、WhatsApp で直接注文を受け取れます。",
    },
    digitalMenuModule: {
      message: "デジタル メニュー モジュール",
    },
    testFor: {
      message: "によるテスト",
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: "この期間の後、月額 R$ 99 のみを支払います。",
    },
    enjoy: {
      message: "楽しみ!",
    },
    month: {
      message: "月",
    },
    annualPlan: {
      message: "年間計画",
    },
    aDigitalSolutionForYourCompany: {
      message: "あなたの会社のためのデジタルソリューション",
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: "ビジネスの管理を容易にする一連の機能にアクセスしてください。",
    },
    receiveYourOrdersByWhatsApp: {
      message: "WhatsAppで注文を受け取ります",
    },
    accessTheDashboard: {
      message: "ダッシュボードにアクセスする",
    },
    registerYourProducts: {
      message: "製品を登録する",
    },
    publishToYourCustomers: {
      message: "お客様に公開",
    },
    whoUsesMyTripMenu: {
      message: "My Trip Menu を使用するのは誰ですか?",
    },
    anIntelligentSolutionForDifferentSegments: {
      message: "さまざまなセグメント向けのインテリジェントなソリューション。",
    },
    restaurants: {
      message: "レストラン",
    },
    coffeeShops: {
      message: "喫茶店",
    },
    fastFoods: {
      message: "ファーストフード",
    },
    bars: {
      message: "バー",
    },
    pubs: {
      message: "パブ",
    },
    foodTrucks: {
      message: "フードトラック",
    },
    bakeries: {
      message: "ベーカリー",
    },
    pizzerias: {
      message: "ピッツェリア",
    },
    companyName: {
      message: "会社名*",
    },
    enterYourCompanyName: {
      message: "会社名を入力します。",
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: "顧客は別のアプリをダウンロードする必要はありません。 スマホのカメラでQRコードをスキャンするだけ！",
    },
    digitizeYourMenu: {
      message: "メニューをデジタル化！",
    },
    moreThan30LanguagesAvailable: {
      message: "30以上の言語が利用可能",
    },
    yourMenuReadyForTheWorld: {
      message: "あなたのメニューは世界に向けて準備ができています",
    },
    quote: {
      message: 'メニューを新製品で酸素化すると、常にメニューを変更するための高い印刷コストが発生します。 My Trip Menu では、新しいドリンクを追加し、最初の数日間で 100 個以上を販売しました。',
    },
    quote2: {
      message: 'エドゥアルド - ハカ パブ',
    },
    multilingual: {
      message: "多言語"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "顧客が利用できる最大 11 の言語"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "ポルトガル語 • 日本語 • スペイン語 • 英語 • ドイツ語 • イタリア語 • 中国語 • 韓国語 • ロシア語 • アラビア語  • フランシス"
    },
    oneClickTranslation: {
      message: "ワンクリック翻訳"
    },
    upToTenLanguagesAvailable: {
      message: "最大10言語が利用可能"
    }
  },
};
