import styled from "styled-components";

export const Container = styled.footer`
  background: #5d5d5d;
  width: 100%;
  height: 60px;
`;

export const Contatos = styled.div`
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RedesSociais = styled.div`
  display: flex;
  img {
    margin-left: 7px;
    cursor: pointer;
  }
`;

export const Logo = styled.img`
  width: 100px;
`;

export const Instagram = styled.img``;

export const Facebook = styled.img``;

export const Twitter = styled.img``;

export const Site = styled.img``;
