import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  .left {
    position: absolute;
    left: -7%;
    bottom: -1rem;
    transform: rotate(15deg);
  }

  .images {
    overflow: hidden;
  }

  .right1 {
    position: absolute;
    right: -1rem;
    bottom: -2rem;
    z-index: -10;
  }

  .right2 {
    position: absolute;
    right: 0;
    bottom: -21rem;
  }
`;

export const AboutContainer = styled.div`
  width: 90%;
  margin: 70px auto;
  display: flex;
  justify-content: space-between;
  text-align: right;

  h1 {
    color: #f37e0c;
    font-size: 54px;
    margin-bottom: 20px;
  }

  h2 {
    color: #606060;
    margin-bottom: 54px;
  }
  h3 {
    width: 90%;
    margin-left: auto;
    color: #606060;
    font-weight: normal;
    margin-bottom: 20px;
    font-size: 19px;
  }

  .wpp-text {
    width: 64%;
    margin-left: auto;
    padding: 4px;
    border: 2px solid #ff952e;
    font-size: 24px;
    font-weight: 700;
    color: #f37e0c;
    text-align: center;
    border-radius: 22px;
    margin-bottom: 48px;
  }
  button {
    font-size: 18px;
  }
  .image {
    width: 70%;
    display: flex;
    justify-content: center;
    img {
      width: 70%;
      max-width: 286px;
    }
  }
`;
