import styled from "styled-components";

export const ContainerGeneral = styled.div`
  .wpp {
    position: absolute;
    right: 8%;
    top: 80%;
    z-index: 1000000;
    position: fixed;
    width: 60px;
    height: 60px;
    text-align: center;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const Container = styled.div``;

export const Imagem = styled.img`
  width: 220px;
  height: 408.16px;
`;

export const ImageContainer = styled.div``;

export const Description = styled.div`
  .web {
    display: flex;
    justify-content: space-between;
    .title-buttons {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 8%;
      .titles {
        h1 {
          font-size: 42px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: 0em;
          text-align: left;
          color: #5d5d5d;
          margin-bottom: 40px;
        }
        h2 {
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 40px;
          color: #5d5d5d;
        }
      }
      .buttons {
        display: flex;
        justify-content: left;
        width: 100%;
        .more {
          cursor: pointer;
          background: #ffffff;
          border: 2px solid #ef7522;
          box-sizing: border-box;
          border-radius: 5px;
          height: 46px;
          width: 146px;
          margin-right: 20px;
          color: #ef7522;
          font-size: 18px;
          font-weight: 700;
          text-align: center;
          display: grid;
          align-items: center;
        }
        .start {
          cursor: pointer;
          height: 46px;
          width: 146px;
          background: #ef7522;
          border: 1px solid #ef7522;
          box-sizing: border-box;
          border-radius: 5px;
          color: white;
          font-size: 18px;
          font-weight: 700;
          text-align: center;
          display: grid;
          align-items: center;
        }
      }
    }

    .images {
      width: 40%;
      position: relative;

      .elemento {
        position: absolute;
        right: 0;
        top: 8rem;
      }

      .iphone {
        position: relative;
        z-index: 1;
        margin-left: 10px;
      }
    }
  }
`;

export const MenuWpp = styled.div`
  padding: 16px;
  border: 2px solid #ff952e;
  border-radius: 32px;
  font-size: 30px;
  width: 80%;
  text-align: center;
  h3 {
    font-weight: normal;
    color: #f37e0c;
  }
  margin-bottom: 16px;
`;

export const ContainerCovid = styled.div`
  position: relative;
`;

export const ContainerText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  margin: 0 auto;
`;

export const TitleCovid = styled.h3`
  font-family: "Biko", sans-serif;
  color: white;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 5px;
  @media (min-width: 562px) {
    font-size: 26px;
  }
  @media (min-width: 1000px) {
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: 0.04em;
  }
`;

export const Title1 = styled.h6`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #ffffff;

  @media (min-width: 562px) {
    font-size: 12px;
  }
  @media (min-width: 1000px) {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }
`;

export const Title2 = styled.h6`
  left: 50%;
  top: 74px;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  margin-top: 22px;

  @media (min-width: 560px) {
    font-size: 14px;
  }
  @media (min-width: 1000px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
  }
`;

export const ContainerWeb = styled.div`
  margin-top: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .elemento02 {
    position: absolute;
    left: -1.5rem;
    margin-top: -20rem;
  }
  .elemento03 {
    position: absolute;
    right: 0;
    bottom: 12rem;
  }
  .picture {
    padding-right: 30px;
    z-index: 1;
    img {
      bottom: -4px;
      position: relative;
    }
  }
  .texts {
    width: 47%;
    margin-right: 28px;
    position: relative;
    z-index: 1;
    text-align: right;
    h1 {
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: right;
      color: #f37e0c;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: right;
      color: #5d5d5d;
      margin-bottom: 40px;
    }

    h3 {
      padding: 10px;
      border: 2px solid #ff952e;
      width: 75%;
      margin-left: auto;
      border-radius: 18px;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: right;
      margin-bottom: 40px;
      color: #ff952e;
      font-weight: normal;
    }
    .accessQR {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: right;
      color: #5d5d5d;
      margin-bottom: 40px;
    }

    button {
      h4 {
        font-size: 18px;
      }
    }
  }
`;

export const ContainerGrey = styled.div`
  width: 100%;
  background-color: #efefef;
  position: relative;
`;
