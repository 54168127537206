/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {

    language: {
      message: 'fr',
    },
    theSmartestSolutionToPresentYourMenu: {
      message: 'La solution la plus intelligente pour présenter votre menu',
    },
    digitalMenu: {
      message: 'Menu numérique',
    },
    ordersBy: {
      message: 'Commandes par',
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: 'My Trip Menu est un menu numérique avec photos, accessible par QR Code directement depuis le smartphone du client. Simple, facile et rapide'
    },
    knowMore: {
      message: 'Apprenez-en plus',
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: 'La solution parfaite pour vos protocoles de sécurité',
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: 'Avec My Trip, vous créez un environnement sûr et empêchez la transmission du nouveau coronavirus.',
    },
    noAppNeeded: {
      message: 'Aucune application nécessaire',
    },
    directAccessByQRCode: {
      message: 'Accès direct par QR Code',
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: "Vos clients n'auront pas besoin de télécharger une autre application. Scannez simplement le code QR, via l'appareil photo du smartphone, et c'est tout!",
    },
    start: {
      message: 'Commencer',
    },
    sellMore: {
      message: 'Vendre plus',
    },
    and: {
      message: 'et',
    },
    save: {
      message: 'sauvegarder',
    },
    withPrintingCosts: {
      message: "sur les frais d'impression",
    },
    freeTrial: {
      message: 'Essai gratuit',
    },
    days: {
      message: 'jours',
    },
    theMostSolution: {
      message: 'Le plus intelligent',
    },
    smartToPresent: {
      message: 'façon de présenter',
    },
    yourMenu: {
      message: 'your menu',
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: 'My Trip Menu est un menu numérique avec des photos',
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'accessible par QR Code directement depuis le smartphone du client',
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'My Trip Menu est un menu numérique avec photos, accessible par QR Code directement depuis le smartphone du client.',
    },
    simpleEasyAndFast: {
      message: 'Simple, facile et rapide',
    },
    simple: {
      message: 'Simple',
    },
    easy: {
      message: 'Facile',
    },
    fast: {
      message: 'rapide',
    },
    digital: {
      message: 'Numérique',
    },
    receiveYourOrders: {
      message: 'Recevez vos commandes',
    },
    by: {
      message: 'par',
    },
    automateTheService: {
      message: 'Automatisez votre ',
    },
    ofYourDelivery: {
      message: 'delivery service',
    },
    automateYourDeliveryService: {
      message: 'Automatisez votre service de livraison',
    },

    yourCustomersAccessA: {
      message: 'Vos clients accèdent à un',
    },
    linkWithYourDigitalMenu: {
      message: 'lien avec votre menu numérique,',
    },
    chooseTheProductsAndYou: {
      message: 'choisissez les produits et vous',
    },
    receiveTheOrderCirectlyOn: {
      message: 'recevoir la commande directement sur',
    },
    speed: {
      message: 'vitesse',
    },
    agility: {
      message: 'Agilité',
    },
    orders: {
      message: 'Ordres',
    },
    profits: {
      message: 'Bénéfices',
    },
    testServiceLink: {
      message: 'Tester le lien du service',
    },
    sellMoreAndSaveOnPrintingCosts: {
    message:  "Vendez plus et économisez sur les coûts d'impression.",
    },
    aDigitalSolution: {
      message: 'Une solution numérique',
    },
    forYourCompany: {
      message: 'pour votre entreprise',
    },

    getAccessToARangeOfFeatures: {
      message: 'Get access to a series of features',
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: 'qui faciliteront la gestion de votre entreprise.',
    },
    fullMenuWithPhotos: {
      message: 'Menu complet avec photos',
    },
    activateAndDeactivateItemsWithEase: {
      message: 'Activez et désactivez facilement des éléments',
    },
    realTimePriceUpdate: {
      message: 'Mise à jour des prix en temps réel',
    },
    testNewProductsQuickly: {
      message: 'Testez rapidement les nouveaux produits',
    },
    selectionFiltersByProductType: {
      message: 'Filtres de sélection par type de produit',
    },
    yourMenuIsReadyInMinutes: {
      message: 'Votre menu est prêt en quelques minutes !',
    },
    loginNow: {
      message: 'Connecte-toi maintenant',
    },
    registrationSuccessful: {
      message: 'Inscription réussi!',
    },
    checkYourEmailInboxAndYourSpam: {
      message: 'Vérifiez votre boîte mail et vos spams.',
    },
    pleaseEnterAValidSubdomain: {
      message: 'Veuillez saisir un sous-domaine valide.',
    },
    checkAllFields: {
      message: 'Vérifiez tous les champs.',
    },
    existingEmailTryAgain: {
      message: 'E-mail existant. Essayer à nouveau.',
    },
    invalidEmailTryAgain: {
      message: 'Email invalide. Essayer à nouveau.',
    },
    invalidLastNameTryAgain: {
      message: 'Nom de famille invalide. Essayer à nouveau.',
    },
    invalidNumberTryAgain: {
      message: 'Numéro invalide. Essayer à nouveau.',
    },
    subdomainAlreadyExistsTryAgain: {
      message: 'Le sous-domaine existe déjà. Essayer à nouveau.',
    },
    invalidSubdomainTryAgain: {
      message: 'Sous-domaine non valide. Essayer à nouveau.',
    },
    invalidCNPJTryAgain: {
      message: 'CNPJ invalide. Essayer à nouveau.',
    },
    CNPJAlreadyRegisteredTryAgain: {
      message: 'CNPJ déjà inscrit. Essayer à nouveau.',
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: "Quelque chose d'inattendu s'est produit. Réessayez plus tard.",
    },
    freeTtrialFor30days: {
      message: 'Essai gratuit pendant 30 jours !',
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: 'Faites votre inscription et découvrez les installations de My Trip Menu dès maintenant',
    },
    makeYourRegistration: {
      message: 'Faites votre inscription',
    },
    aboutYou: {
      message:'Au propos de vous',
    },
    mandatoryFilling: {
      message: 'remplissage obligatoire',
    },
    name: {
      message: 'Nom*',
    },
    lastName: {
      message: 'nom de famille*',
    },
    enterYourLastName: {
      message: 'Entrez votre nom de famille.',
    },
    firstName: {
      message: 'prénom*',
    },
    enterYourName: {
      message: 'Eter votre nom.',
    },
    secondName: {
      message: 'Nom de famille*',
    },
    enterYourSecondName: {
      message: 'Entrez votre deuxième prénom.',
    },
    contactEmail: {
      message: 'Courriel du contact*',
    },
    enterYourEmail: {
      message: 'Entrez votre Email.',
    },
    mobileWhatsapp: {
      message: 'Mobile/WhatsApp',
    },
    enterYourContactNumber: {
      message: 'Entrez votre numéro de téléphone.',
    },
    pleaseEnterYourValidNumber: {
      message: 'Entrez votre numéro valide.',
    },
    aboutTheEstablishments: {
      message: 'Sur les établissements',
    },
    nameOfTheEstablishment: {
      message: "Nom de l'établissement*",
    },
    enterTheNameOfYourEstablishment: {
      message: 'Entrez le nom de votre établissement.',
    },
    enterYourCNPJ: {
      message: 'Entrez votre CNPJ.',
    },
    enterAValidCNPJ: {
      message: 'Entrez un CNPJ valide.',
    },
    chooseAURLForYourMenu: {
      message: 'Choisissez une URL pour votre menu*',
    },
    emailForLogosInTheManager: {
      message: 'E-mail pour les logos dans le gestionnaire*',
    },
    enterAValidEmail: {
      message: 'Entrer un email valide',
    },
    chooseASubdomainForYourMenu: {
      message: 'Choisissez un sous-domaine pour votre menu*',
    },
    about: {
      message: 'À propos',
    },
    solution: {
      message: 'Solution',
    },
    functionality: {
      message: 'Fonctionnalité',
    },
    whoUse: {
      message: 'Qui utilise ?',
    },
    prices: {
      message: 'Des prix',
    },
    login: {
      message: 'Connexion',
    },
    register: {
      message: 'Enregistrer',
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: 'Vos clients accèdent à un lien avec votre menu digital, choisissent les produits et vous recevez la commande directement sur WhatsApp.',
    },
    digitalMenuModule: {
      message: 'Module de menu numérique',
    },
    testFor: {
      message: 'Test pour',
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: 'après cette période, ne payez que 99 R$ par mois.',
    },
    enjoy: {
      message: 'Profitez!',
    },
    month: {
      message: 'mois',
    },
    annualPlan: {
      message: 'plan annuel',
    },
    aDigitalSolutionForYourCompany: {
      message: 'Une solution digitale pour votre entreprise',
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: 'Accédez à une série de fonctionnalités qui faciliteront la gestion de votre entreprise.',
    },
    receiveYourOrdersByWhatsApp: {
      message: 'Recevez vos commandes par WhatsApp',
    },
    accessTheDashboard: {
      message: 'accéder au tableau de bord',
    },
    registerYourProducts: {
      message: 'Enregistrez vos produits',
    },
    publishToYourCustomers: {
      message: 'Publiez pour vos clients',
    },
    whoUsesMyTripMenu: {
      message: 'Qui utilise le menu Mon voyage?',
    },
    anIntelligentSolutionForDifferentSegments: {
      message: 'Une solution intelligente pour différents segments.',
    },
    restaurants: {
      message: 'Restaurants',
    },
    coffeeShops: {
      message: 'Les cafés',
    },
    fastFoods: {
      message: 'Restauration rapide',
    },
    bars: {
      message: 'barres',
    },
    pubs: {
      message: 'Pubs',
    },
    foodTrucks: {
      message: 'Camions de nourriture',
    },
    bakeries: {
      message: 'Boulangeries',
    },
    pizzerias: {
      message: 'Pizzérias',
    },
    companyName: {
      message: "Nom de l'entreprise*",
    },
    enterYourCompanyName: {
      message: 'Enter your company name.',
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: "Vos clients n'auront pas besoin de télécharger une autre application. Scannez simplement le code QR, via l'appareil photo du smartphone, et c'est tout !",
    },
    digitizeYourMenu: {
      message: 'Numérisez votre menu!',
    },
    moreThan30LanguagesAvailable: {
      message: 'Plus de 30 langues disponibles',
    },
    yourMenuReadyForTheWorld: {
      message: 'Votre menu prêt pour le monde',
    },
    quote: {
      message: "Oxygéner la carte avec de nouveaux produits génère toujours des coûts d'impression élevés pour changer de carte. Avec My Trip Menu, nous avons ajouté une nouvelle boisson et vendu plus de 100 unités dans les premiers jours.',"},
    quote2: {
      message: 'Eduardo - Haka Pub',
    },
    multilingual: {
      message: "multilingue"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "Jusqu'à 11 langues disponibles pour votre client"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "Portugais • Japonais • Espagnol • Anglais • Allemand • Italien • Chinois • Coréen • Russe • Arabic • Français"
    },
    oneClickTranslation: {
      message: "Traduction en un clic"
    },
    upToTenLanguagesAvailable: {
      message: "Jusqu'à 11 langues disponibles"
    }    
  }
};
