import React from "react";

import {
  Container,
  Elemento10,
  Elemento8,
  Valores,
  Real1,
  Real2,
  RealMes,
  RealMes2,
  Mes,
  Plano,
  ContainerValues,
  Negrito,
  Subtitulo,
  Titulo,
} from "./styles";

import iphone4 from "../../assets/images7/iphone4.png";

import Element8 from "../../assets/images7/elemento8.png";
import Element10 from "../../assets/images7/elemento10.png";
import TranslatorFunction from '../I18n/Translator';

function PlansMobile({ LitleDivisor }) {

  const digitalMenuModule = TranslatorFunction({ path: 'digitalMenuModule.message' });
  const testFor = TranslatorFunction({ path: 'testFor.message' });
  const days = TranslatorFunction({ path: 'days.message' });
  const afterThisPeriodPayOnlyR99PerMonth = TranslatorFunction({ path: 'afterThisPeriodPayOnlyR99PerMonth.message' });
  const enjoy = TranslatorFunction({ path: 'enjoy.message' });
  const month = TranslatorFunction({ path: 'month.message' });
  const annualPlan = TranslatorFunction({ path: 'annualPlan.message' });

  return (
    <Container>
      <Titulo>{digitalMenuModule}</Titulo>
      <LitleDivisor />
      <Subtitulo>
        {testFor}
        <Negrito> 30 {days}</Negrito>, {afterThisPeriodPayOnlyR99PerMonth} <Negrito>{enjoy}</Negrito>
      </Subtitulo>
      <ContainerValues>
        <Elemento8 src={Element8} />
        <Elemento10 src={Element10} />
        <Valores>
          <div className="old-price">
            <Real1>R$</Real1>
            <RealMes>159/{month}</RealMes>
          </div>
          <div className="new-price">
            <Real2>R$</Real2>
            <RealMes2>99</RealMes2>
            <Mes>/{month}</Mes>
          </div>
          <Plano>{annualPlan}</Plano>
        </Valores>
      </ContainerValues>
      <img src={iphone4} alt="" />
    </Container>
  );
}

export default PlansMobile;
