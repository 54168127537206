import styled from "styled-components";

export const Container = styled.div`
  margin-top: 10px;

  .element01 {
    position: absolute;
    right: 0;
    top: 30px;
  }
`;

export const Texts = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  h1 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #7b7b7b;
  }
  h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #959595;
    margin-bottom: 40px;
  }
  .tab {
    margin-top: 17px;
    margin-bottom: 17px;
    width: 83px;
    height: 5px;
    background: #ff952e;
  }
`;

export const Content = styled.div`
  z-index: 1;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;
  .icons-one {
    div {
      display: flex;
      justify-content: space-between;
      width: 80%;
      align-items: center;
      text-align: right;
      margin-bottom: 64px;
      margin-left: auto;
      img {
        height: 51px;
        width: 72px;
      }
      h4 {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: right;
        color: #959595;
        margin-right: 36px;
      }
    }
  }

  .icons-two {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      text-align: right;
      margin-bottom: 64px;
      img {
        height: 51px;
        width: 72px;
      }
      h4 {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #959595;
        margin-left: 36px;
      }
    }
  }
`;
