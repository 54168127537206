import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 64px;

  .left {
    position: absolute;
    left: -10%;
    bottom: 0;
    z-index: -10;
  }

  .map {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
  }

  .images {
    overflow: hidden;
  }

  .right {
    position: absolute;
    right: -2rem;
    top: -5rem;
    z-index: -10;
  }
`;

export const AboutContainer = styled.div`
  width: 80%;
  margin: 70px auto;
  display: flex;
  justify-content: center;

  .image {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      max-width: 286px;
    }
  }

  .texts {
    margin-left: 10px;
    width: 100%;

    h1 {
      color: #f37e0c;
      font-size: 54px;
      margin-bottom: 20px;
      text-align: left;
    }
    h2 {
      color: #606060;
      text-align: left;
    }

    h3 {
      width: 100%;
      text-align: left;
      color: #606060;
      font-weight: 300;
      margin-bottom: 20px;
      font-size: 19px;
      margin-top: 50px;
    }

    div.onClickTranslateBox {
      width: 100%;
      text-align: center;
      border: 3px solid #ff952e;
      border-radius: 29px;
      color: #ff952e;
      padding: 10px;
      font-size: 20px;
      font-weight: 700;
      margin-top: 65px;
    }
  }
`;

export const Button = styled.button`
  cursor: pointer;
  width: 201px;
  height: 61px;
  background: #ef7522;
  border: 1px solid #ef7522;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 18px;
  font-size: 15px;
  margin-top: 40px;
  display: block;
  @media (max-width: 376px) {
    width: 156px;
    height: 50px;
  }

  h2 {
    text-align: center !important;
    color: #fff !important;
    margin-bottom: 5px;
  }

  p {
    font-size: 10px;
    font-weight: 300;
  }
`;
