/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    language: {
      message: 'pt-PT',
    },
    theSmartestSolutionToPresentYourMenu: {
      message: 'A solução mais inteligente para apresentar o seu menu',
    },
    digitalMenu: {
      message: 'Menu digital',
    },
    ordersBy: {
      message: 'pedidos por',
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: 'O My Trip Viagem é um menu digital com fotos, acessado por QR Code diretamente pelo smartphone do cliente. Simples, fácil e rápido.',
    },
    knowMore: {
      message: 'Saiba mais',
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: 'A solução perfeita para os seus protocolos de segurança',
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: 'Com My Trip você cria um ambiente seguro e a evita transmissão do novo coronavírus.',
    },
    noAppNeeded: {
      message: 'Não precisa de app',
    },
    directAccessByQRCode: {
      message: 'Acesso direto pelo QR Code',
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: 'Os seus clientes não precisarão fazer o download de mais um aplicativo. Basta fazer a leitura do QR Code, através da câmera do smartphone, e pronto!',
    },
    start: {
      message: 'Começar',
    },
    sellMore: {
      message: 'Venda mais',
    },
    and: {
      message: 'e',
    },
    save: {
      message: 'economize',
    },
    withPrintingCosts: {
      message: 'com os custos de impressão.',
    },
    freeTrial: {
      message: 'Teste grátis',
    },
    days: {
      message: 'dias',
    },
    theMostSolution: {
      message: 'A solução mais',
    },
    smartToPresent: {
      message: 'inteligente de apresentar',
    },
    yourMenu: {
      message: 'o seu cardápio',
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: 'O My Trip Menu é um cardápio digital com fotos',
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'acessado por QR Code direto pelo smartphone do cliente',
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'O My Trip Menu é um cardápio digital com fotos, acessado por QR Code direto pelo smartphone do cliente.',
    },
    simpleEasyAndFast: {
      message: 'Simples, fácil e rápido',
    },
    simple: {
      message: 'Simples',
    },
    easy: {
      message: 'fácil',
    },
    fast: {
      message: 'rápido',
    },
    digital: {
      message: 'Digital',
    },
    receiveYourOrders: {
      message: 'Receba seus pedidos',
    },
    by: {
      message: 'por',
    },
    automateTheService: {
      message: 'Automatize o atendimento',
    },
    ofYourDelivery: {
      message: 'do seu delivery',
    },
    automateYourDeliveryService: {
      message: 'Automatize o atendimento do seu delivery',
    },

    yourCustomersAccessA: {
      message: 'Seus clientes acessam um',
    },
    linkWithYourDigitalMenu: {
      message: 'link com o seu cardápio digital,',
    },
    chooseTheProductsAndYou: {
      message: 'escolhem os produtos e você',
    },
    receiveTheOrderCirectlyOn: {
      message: 'recebe o pedido direto no',
    },
    speed: {
      message: 'Rapidez',
    },
    agility: {
      message: 'Agilidade',
    },
    orders: {
      message: 'Pedido',
    },
    profits: {
      message: 'Lucros',
    },
    testServiceLink: {
      message: 'Testar link de atendimento',
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: 'Venda mais e economize com os custos de impressão.',
    },
    aDigitalSolution: {
      message: 'Uma solução digital',
    },
    forYourCompany: {
      message: 'para a sua empresa',
    },

    getAccessToARangeOfFeatures: {
      message: 'Tenha acesso a uma série de funcionalidades',
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: 'que facilitarão a gestão do seu negócio.',
    },
    fullMenuWithPhotos: {
      message: 'Menu completo com fotos',
    },
    activateAndDeactivateItemsWithEase: {
      message: 'Ative e desative itens com facilidade',
    },
    realTimePriceUpdate: {
      message: 'Atualização de preço em tempo real',
    },
    testNewProductsQuickly: {
      message: 'Teste novos produtos com rapidez',
    },
    selectionFiltersByProductType: {
      message: 'Filtros de seleção por tipo de produto',
    },
    yourMenuIsReadyInMinutes: {
      message: 'O seu cardápio fica pronto em minutos!',
    },

    loginNow: {
      message: 'Acesse agora',
    },
    registrationSuccessful: {
      message: 'Cadastro feito com sucesso!',
    },
    checkYourEmailInboxAndYourSpam: {
      message: 'Verifique sua caixa de e-mail e seu spam.',
    },
    pleaseEnterAValidSubdomain: {
      message: 'Insira um subdomínio válido.',
    },
    checkAllFields: {
      message: 'Verifique todos os campos.',
    },
    existingEmailTryAgain: {
      message: 'E-mail existente. Tente novamente.',
    },
    invalidEmailTryAgain: {
      message: 'E-mail inválido. Tente novamente.',
    },
    invalidLastNameTryAgain: {
      message: 'Sobrenome inválido. Tente novamente',
    },
    invalidNumberTryAgain: {
      message: 'Número inválido. Tente novamente',
    },
    subdomainAlreadyExistsTryAgain: {
      message: 'Subdomínio já existe. Tente novamente.',
    },
    invalidSubdomainTryAgain: {
      message: 'Subdomínio inválido. Tente novamente.',
    },
    invalidCNPJTryAgain: {
      message: 'CNPJ inválido. Tente novamente.',
    },

    CNPJAlreadyRegisteredTryAgain: {
      message: 'CNPJ já cadastrado. Tente novamente.',
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: 'Algo inexperado aconteceu. Tente novamente mais tarde.',
    },
    freeTtrialFor30days: {
      message: 'Teste grátis por 30 dias!',
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: 'Faça o seu cadastro e experimente as facilidades do My Trip Menu agora mesmo',
    },
    makeYourRegistration: {
      message: 'Faça o seu cadastro',
    },
    aboutYou: {
      message: 'Sobre você',
    },
    mandatoryFilling: {
      message: 'preenchimento obrigatório',
    },
    name: {
      message: 'Nome*',
    },
    lastName: {
      message: 'Sobrenome*',
    },
    enterYourLastName: {
      message: 'Insira seu sobrenome.',
    },
    firstName: {
      message: 'Primeiro nome*',
    },
    enterYourName: {
      message: 'Insira seu nome.',
    },
    secondName: {
      message: 'Segundo nome*',
    },
    enterYourSecondName: {
      message: 'Insira seu segundo nome.',
    },
    contactEmail: {
      message: 'E-mail para contato*',
    },
    enterYourEmail: {
      message: 'Insira seu e-mail.',
    },
    mobileWhatsapp: {
      message: 'Celular/Whatsapp',
    },
    enterYourContactNumber: {
      message: 'Insira seu número para contato.',
    },
    pleaseEnterYourValidNumber: {
      message: 'Insira seu número válido.',
    },
    aboutTheEstablishments: {
      message: 'Sobre os estabelecimentos',
    },
    nameOfTheEstablishment: {
      message: 'Nome do Estabelecimento*',
    },
    enterTheNameOfYourEstablishment: {
      message: 'Insira o nome do seu estabelecimento.',
    },
    enterYourCNPJ: {
      message: 'Insira seu CNPJ.',
    },
    enterAValidCNPJ: {
      message: 'Insira um CNPJ válido.',
    },
    chooseAURLForYourMenu: {
      message: 'Escolha uma URL para o seu cardapio*',
    },
    emailForLogosInTheManager: {
      message: 'E-mail para logos no gerenciador*',
    },
    enterAValidEmail: {
      message: 'Insira um e-mail válido',
    },
    chooseASubdomainForYourMenu: {
      message: 'Escolha um subdomínio para o seu cardápio*',
    },
    about: {
      message: 'Sobre',
    },
    solution: {
      message: 'Solução',
    },
    functionality: {
      message: 'Funcionalidade',
    },
    whoUse: {
      message: 'Quem usa?',
    },
    prices: {
      message: 'Preços',
    },
    login: {
      message: 'Login',
    },
    register: {
      message: 'Cadastrar',
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: 'Seus clientes acessam um link com o seu cardápio digital, escolhem os produtos e você recebe o pedido direto no WhatsApp.',
    },
    digitalMenuModule: {
      message: 'Módulo Cardápio Digital',
    },
    testFor: {
      message: 'Teste por',
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: 'após este período, pague apenas R$ 99 por mês.',
    },
    enjoy: {
      message: 'Aproveite!',
    },
    month: {
      message: 'mês',
    },
    annualPlan: {
      message: 'plano anual',
    },
    aDigitalSolutionForYourCompany: {
      message: 'Uma solução digital para a sua empresa',
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: 'Tenha acesso a uma série de funcionalidades que facilitarão a gestão do seu negócio.',
    },
    receiveYourOrdersByWhatsApp: {
      message: 'Receba seus pedidos por WhatsApp',
    },
    accessTheDashboard: {
      message: 'Acesse o painel',
    },
    registerYourProducts: {
      message: 'Cadastre os seus produtos',
    },
    publishToYourCustomers: {
      message: 'Publique para os seus clientes',
    },
    whoUsesMyTripMenu: {
      message: 'Quem usa o My Trip Menu?',
    },
    anIntelligentSolutionForDifferentSegments: {
      message: 'Uma solução inteligente para diferentes segmentos.',
    },
    restaurants: {
      message: 'Restaurantes',
    },
    coffeeShops: {
      message: 'Cafeterias',
    },
    fastFoods: {
      message: 'Fast Foods',
    },
    bars: {
      message: 'Bares',
    },
    pubs: {
      message: 'Pubs',
    },
    foodTrucks: {
      message: 'Food Trucks',
    },
    bakeries: {
      message: 'Padarias',
    },
    pizzerias: {
      message: 'Pizzarias',
    },
    companyName: {
      message: 'Razão Social*',
    },
    enterYourCompanyName: {
      message: 'Insira sua razão social.',
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: 'Os seus clientes não precisarão fazer o download de mais um aplicativo. Basta fazer a leitura do QR Code, através da câmera do smartphone, e pronto!',
    },
    digitizeYourMenu: {
      message: 'Digitalize seu cardápio!',
    },
    moreThan30LanguagesAvailable: {
      message: 'Mais de 30 idiomas disponíveis',
    },
    yourMenuReadyForTheWorld: {
      message: 'Seu cardápio pronto para o mundo',
    },
    quote: {
      message: 'Oxigenar o menu com produtos novos sempre gera alto custo de impressões para troca de cardápios. Com o My Trip Menu colocamos um drink novo e vendemos mais de 100 unidades nos primeiros dias',
    },
    quote2: {
      message: 'Eduardo - Haka Pub',
    },
    multilingual: {
      message: "multilingue"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "Até 11 idiomas disponíveis para o seu cliente"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "Português • Japonês • Espanhol • Inglês • Alemão • Italiano • Chinês • Coreano • Russo • Árabe • Francês"
    },
    oneClickTranslation: {
      message: "Tradução com um clique"
    },
    upToTenLanguagesAvailable: {
      message: "Até 11 idiomas disponíveis"
    }
  },
};
