import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import Funcionalidade from "../pages/Funcionalidade";
import Precos from "../pages/Precos";
import QuemUsa from "../pages/QuemUsa";
import Sobre from "../pages/Sobre";
import Solucao from "../pages/Solucao";

const routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/funcionalidade" component={Funcionalidade} />
      <Route exact path="/precos" component={Precos} />
      <Route exact path="/quem-usa" component={QuemUsa} />
      <Route exact path="/sobre" component={Sobre} />
      <Route exact path="/solucao" component={Solucao} />
    </Switch>
  );
};

export default routes;
