import React from "react";
import { Container } from "./styles";
import menu from "../../assets/logo_mtm_color.png";
import I18n from '../../components/I18n';

function HeaderMobile() {
  return (
    
    <Container>
        <img id ="logo" src={menu} alt="" />

        <div style={{position: 'absolute', top: '51px', right: '0'}}>
          <I18n id = "container" />
        </div>
    </Container>

    
  );
}

export default HeaderMobile;
