/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    language: {
      message: 'de',
    },
    theSmartestSolutionToPresentYourMenu: {
      message: 'Die intelligenteste Lösung, um Ihre Speisekarte zu präsentieren',
    },
    digitalMenu: {
      message: 'digitale Speisekarte',
    },
    ordersBy: {
      message: 'bestellt von',
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message:'Mein Reisemenü ist ein digitales Menü mit Fotos, auf das per QR-Code direkt vom Smartphone des Kunden zugegriffen werden kann. Einfach, leicht und schnell.',
    },
    knowMore: {
      message: 'Mehr wissen',
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: 'Die perfekte Lösung für Ihre Sicherheitsprotokolle',
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: 'Mit My Trip schaffen Sie eine sichere Umgebung und verhindern die Übertragung des neuen Coronavirus.',
    },
    noAppNeeded: {
      message: 'keine App erforderlich',
    },
    directAccessByQRCode: {
      message: 'Direktzugriff per QR-Code',
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: 'Ihre Kunden müssen keine weitere App herunterladen. Scannen Sie einfach den QR-Code mit der Smartphone-Kamera und fertig!',
    },
    start: {
      message: 'Anfangen',
    },
    sellMore: {
      message: 'Mehr verkaufen',
    },
    and: {
      message: 'und',
    },
    save: {
      message: 'sparen',
    },
    withPrintingCosts: {
      message: 'mit Druckkosten.',
    },
    freeTrial: {
      message: 'Kostenlose Testphase',
    },
    days: {
      message: 'Tage',
    },
    theMostSolution: {
      message: 'Die beste Lösung',
    },
    smartToPresent: {
      message: 'intelligent zu präsentieren',
    },
    yourMenu: {
      message: 'Ihr Menü',
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: 'O My Trip menu é um cardápio digital com fotos',
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'Zugriff per QR-Code direkt vom Smartphone des Kunden',
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'My Trip Menu ist ein digitales Menü mit Fotos, auf das per QR-Code direkt vom Smartphone des Kunden zugegriffen werden kann.',
    },
    simpleEasyAndFast: {
      message: 'Einfach, einfach und schnell',
    },
    simple: {
      message: 'Einfach',
    },
    easy: {
      message: 'einfach',
    },
    fast: {
      message: 'schnell',
    },
    digital: {
      message: 'Digital',
    },
    receiveYourOrders: {
      message: 'Holen Sie Ihre Bestellungen',
    },
    by: {
      message: 'pro',
    },
    automateTheService: {
      message: 'Automatisieren Sie den Dienst',
    },
    ofYourDelivery: {
      message: 'Ihrer Lieferung',
    },
    automateYourDeliveryService: {
      message: 'Automatisieren Sie Ihren Lieferservice',
    },

    yourCustomersAccessA: {
      message: 'Ihre Kunden greifen auf einen zu',
    },
    linkWithYourDigitalMenu: {
      message: 'Link zu Ihrer digitalen Speisekarte',
    },
    chooseTheProductsAndYou: {
      message: 'Sie wählen die Produkte und Sie',
    },
    receiveTheOrderCirectlyOn: {
      message: 'wählen Sie die Produkte aus und Sie erhalten die Bestellung direkt am',
    },
    speed: {
      message: 'Geschwindigkeit',
    },
    agility: {
      message: 'Beweglichkeit',
    },
    orders: {
      message: 'Anfrage',
    },
    profits: {
      message: 'Profite',
    },
    testServiceLink: {
      message: 'Service-Link testen',
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: 'Mehr verkaufen und Druckkosten sparen.',
    },
    aDigitalSolution: {
      message: 'Eine digitale Lösung',
    },
    forYourCompany: {
      message: 'für Ihr Unternehmen',
    },
    getAccessToARangeOfFeatures: {
      message: 'Erhalten Sie Zugriff auf eine Reihe von Funktionen',
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: 'die die Verwaltung Ihres Unternehmens erleichtern.',
    },
    fullMenuWithPhotos: {
      message: 'Vollständiges Menü mit Fotos',
    },
    activateAndDeactivateItemsWithEase: {
      message: 'Elemente einfach ein- und ausschalten',
    },
    realTimePriceUpdate: {
      message: 'Preisaktualisierung in Echtzeit',
    },
    testNewProductsQuickly: {
      message: 'Neue Produkte schnell testen',
    },
    selectionFiltersByProductType: {
      message: 'Auswahlfilter nach Produkttyp',
    },
    yourMenuIsReadyInMinutes: {
      message: 'Ihr Menü ist in wenigen Minuten fertig!',
    },
    loginNow: {
      message: 'jetzt einloggen',
    },
    registrationSuccessful: {
      message: 'Registrierung erfolgreich!',
    },
    checkYourEmailInboxAndYourSpam: {
      message: 'Überprüfen Sie Ihren E-Mail-Posteingang und Ihren Spam.',
    },
    pleaseEnterAValidSubdomain: {
      message: 'Bitte geben Sie eine gültige Subdomain ein.',
    },
    checkAllFields: {
      message: 'Alle Felder markieren.',
    },
    existingEmailTryAgain: {
      message: 'Vorhandene E-Mail. Versuchen Sie es nochmal.',
    },
    invalidEmailTryAgain: {
      message: 'Ungültige E-Mail. Versuchen Sie es nochmal.',
    },
    invalidLastNameTryAgain: {
      message: 'Ungültiger Nachname. Versuchen Sie es nochmal',
    },
    invalidNumberTryAgain: {
      message: 'Ungültige Nummer. Versuchen Sie es nochmal',
    },
    subdomainAlreadyExistsTryAgain: {
      message: 'Subdomain existiert bereits. Versuchen Sie es nochmal.',
    },
    invalidSubdomainTryAgain: {
      message: 'Ungültige Subdomain. Versuchen Sie es nochmal.',
    },
    invalidCNPJTryAgain: {
      message: 'Ungültiger CNPJ. Versuchen Sie es nochmal.',
    },
    CNPJAlreadyRegisteredTryAgain: {
      message: 'CNPJ ist bereits registriert. Versuchen Sie es nochmal.',
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: 'Etwas Unerwartetes ist passiert. Versuchen Sie es später noch einmal.',
    },
    freeTtrialFor30days: {
      message: 'Kostenlose 30-Tage-Testversion!',
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: 'Melden Sie sich an und erleben Sie die Funktionen von My Trip Menu gleich jetzt',
    },
    makeYourRegistration: {
      message: 'Registrieren',
    },
    aboutYou: {
      message: 'Über dich',
    },
    mandatoryFilling: {
      message: 'obligatorisch',
    },
    name: {
      message:'Name*',
    },
    lastName: {
      message: 'Nachname*',
    },
    enterYourLastName: {
      message: 'Geben Sie Ihren Nachnamen ein.',
    },
    firstName: {
      message: 'Vorname*',
    },
    enterYourName: {
      message: 'Gib deinen Namen ein.',
    },
    secondName: {
      message: 'Zweitname*',
    },
    enterYourSecondName: {
      message: 'Geben Sie Ihren zweiten Vornamen ein.',
    },
    contactEmail: {
      message: 'Kontakt E-mail*',
    },
    enterYourEmail: {
      message: 'Geben sie ihre E-Mail Adresse ein.',
    },
    mobileWhatsapp: {
      message: 'Handy/Whatsapp',
    },
    enterYourContactNumber: {
      message: 'Geben Sie Ihre Kontaktnummer ein.',
    },
    pleaseEnterYourValidNumber: {
      message: 'Bitte geben Sie Ihre gültige Nummer ein.',
    },
    aboutTheEstablishments: {
      message: 'Über die Betriebe',
    },
    nameOfTheEstablishment: {
      message: 'Name des Betriebs*',
    },
    enterTheNameOfYourEstablishment: {
      message: 'Geben Sie den Namen Ihrer Einrichtung ein.',
    },
    enterYourCNPJ: {
      message: 'Geben Sie Ihre CNPJ ein.',
    },
    enterAValidCNPJ: {
      message: 'Geben Sie einen gültigen CNPJ ein.',
    },
    chooseAURLForYourMenu: {
      message:'Wählen Sie eine URL für Ihr Menü*',
    },
    emailForLogosInTheManager: {
      message: 'E-Mail an Logos im Manager*',
    },
    enterAValidEmail: {
      message: 'Bitte geben Sie eine gültige Email-Adresse ein',
    },
    chooseASubdomainForYourMenu: {
      message:'Wählen Sie eine Subdomain für Ihr Menü*',
    },
    about: {
      message: 'Um',
    },
    solution: {
      message: 'Lösung',
    },
    functionality: {
      message: 'Funktionalität',
    },
    whoUse: {
      message: 'Wer benutzt?',
    },
    prices: {
      message: 'Preise',
    },
    login: {
      message: 'Anmeldung',
    },
    register: {
      message: 'Registrieren',
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: 'Ihre Kunden greifen auf einen Link mit Ihrer digitalen Speisekarte zu, wählen die Produkte aus und Sie erhalten die Bestellung direkt auf WhatsApp.',
    },
    digitalMenuModule: {
      message:'Digitales Menümodul',
    },
    testFor: {
      message: 'testen durch',
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: 'nach diesem Zeitraum zahlen Sie nur 99 R$ pro Monat.',
    },
    enjoy: {
      message: 'Genießen!',
    },
    month: {
      message:'Monat',
    },
    annualPlan: {
      message:'Jahresplan',
    },
    aDigitalSolutionForYourCompany: {
      message: 'Eine digitale Lösung für Ihr Unternehmen',
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: 'Haben Sie Zugriff auf eine Reihe von Funktionen, die Ihnen die Verwaltung Ihres Unternehmens erleichtern werden.',
    },
    receiveYourOrdersByWhatsApp: {
      message: 'Bestellungen per WhatsApp erhalten',
    },
    accessTheDashboard: {
      message: 'Zum Dashboard gehen',
    },
    registerYourProducts: {
      message: 'Registrieren Sie Ihre Produkte',
    },
    publishToYourCustomers: {
      message: 'Veröffentlichen Sie für Ihre Kunden',
    },
    whoUsesMyTripMenu: {
      message: 'Wer verwendet mein Reisemenü?',
    },
    anIntelligentSolutionForDifferentSegments: {
      message: 'Eine intelligente Lösung für verschiedene Segmente.',
    },
    restaurants: {
      message: 'Restaurants',
    },
    coffeeShops: {
      message: 'Cafés',
    },
    fastFoods: {
      message: 'Fast Foods',
    },
    bars: {
      message:'Riegel',
    },
    pubs: {
      message: 'Kneipen',
    },
    foodTrucks: {
      message: 'Imbisswagen',
    },
    bakeries: {
      message: 'Bäckereien',
    },
    pizzerias: {
      message: 'Pizzerien',
    },
    companyName: {
      message: 'Soziale Gründe*',
    },
    enterYourCompanyName: {
      message: 'Geben Sie Ihren Firmennamen ein.',
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: 'Ihre Kunden müssen keine weitere App herunterladen. Scannen Sie einfach den QR-Code mit der Smartphone-Kamera und fertig!',
    },
    digitizeYourMenu: {
      message: 'Scannen Sie Ihre Speisekarte!',
    },
    moreThan30LanguagesAvailable: {
      message: 'Über 30 Sprachen verfügbar',
    },
    yourMenuReadyForTheWorld: {
      message: 'Ihr Menü bereit für die Welt',
    },
    quote: {
      message: 'Das Anreichern der Speisekarte mit neuen Produkten verursacht immer hohe Druckkosten für wechselnde Speisekarten. Mit My Trip Menu haben wir ein neues Getränk hinzugefügt und in den ersten Tagen mehr als 100 Einheiten verkauft.',
    },
    quote2: {
      message: 'Eduardo - Haka Pub',
    },
    multilingual: {
      message: "mehrsprachig"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "Bis zu 11 Sprachen für Ihren Kunden verfügbar"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "Portugiesisch • Japanisch • Spanisch • Englisch • Deutsch • Italienisch • Chinesisch • Koreanisch • Russisch • Arabisch • Französisch"
    },
    oneClickTranslation: {
      message: "Übersetzung mit einem Klick"
    },
    upToTenLanguagesAvailable: {
      message: "Bis zu 11 Sprachen verfügbar"
    }
  },
};
