import React from "react";

import { Container } from "./styles";

const Precos = () => {
  return (
    <Container>
      <h1>Precos</h1>
    </Container>
  );
};

export default Precos;
